let widgetEndPoint = "https://api.fastroomz.in/api/v1/booking/widget";
const bookingEngineUrl = "https://booking.fastroomz.in";

// let widgetEndPoint = "http://localhost:5000/api/v1/booking/widget";
// const bookingEngineUrl = "http://localhost:3000";

export async function fetchApi() {
  try {
    const payload = {
      Code,
    };
    const response = await fetch(widgetEndPoint, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });

    if (!response.ok) throw new Error("Error in fetching details");
    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
    throw err;
  }
}

export function urlGenerator(payload) {
  const {
    propertyName,
    checkInDate,
    checkOutDate,
    room,
    adult,
    child,
    propertyCode,
  } = payload;

  const formattedPropertyName = propertyName
    .toLowerCase()
    .trim()
    .replace(/\s+/g, "-");

  const newUrl = new URL(
    `hotel/${formattedPropertyName}-${propertyCode}`,
    bookingEngineUrl
  );

  newUrl.search = new URLSearchParams({
    r: room,
    c: child,
    a: adult,
    from: checkInDate,
    to: checkOutDate,
    code: propertyCode,
  });

  return newUrl.toString();
}

document.addEventListener("DOMContentLoaded", () => {
  const style = document.createElement("style");

  style.textContent = `
  @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");
    body {
      position: relative;
      margin: 0;
      padding: 0;
    }

    .fzbe__container {
      position: fixed;
      bottom: 10px;
      left: 20px;
      right: 20px;
      background-color: rgba(241, 241, 241, 0.9);
      font-family: "Poppins", sans-serif;
    }
    .fzbe__container .fzbe__form {
      display: flex;
      justify-content: center;
      gap: 1rem;
      padding: 1rem;
      flex-direction: column;
      flex-wrap: wrap;
    }
    .fzbe__container .fzbe__form .fzbe__input-container {
      display: flex;
      gap: 10px;
      justify-content: space-between;
      flex: 1;
      flex-wrap: wrap;
    }
    .fzbe__container .fzbe__form .fzbe__input-container .fzbe__form-control {
      flex: 1 1 calc(100% / 5 - 15px);
      min-width: 120px;
    }
    .fzbe__container .fzbe__form .fzbe__input-container .fzbe__form-control .fzbe__label {
      font-weight: 500;
      font-size: 1rem;
      display: block;
    }
    .fzbe__container .fzbe__form .fzbe__input-container .fzbe__form-control .fzbe__input {
      font-weight: 400;
      font-size: 1rem;
      padding: 0.375rem 0.75rem;
      line-height: 1.5;
      color: #212529;
      background-color: white;
      background-clip: padding-box;
      border: 1px solid #dee2e6;
      border-radius: 0.375rem;
      outline: none;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      width: 100%;
      box-sizing: border-box;
    }
    .fzbe__container .fzbe__form .fzbe__input-container .fzbe__form-control:not([style*="display: none;"]) {
      flex: 1 1 calc(100% / 6 - 15px);
    }
    .fzbe__container .fzbe__form .fzbe__input-container .fzbe__form-control.hidden {
      display: none;
    }
    @media (max-width: 768px) {
      .fzbe__container .fzbe__form .fzbe__input-container {
        flex-direction: column;
      }
    }
    .fzbe__container .fzbe__form .fzbe__btn-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .fzbe__container .fzbe__form .fzbe__btn-container .fzbe__btn {
      background-color: rgb(111, 111, 234);
      padding: 1rem 3rem;
      border: none;
      border-radius: 0.375rem;
      color: white;
      font-weight: 600;
      font-size: 1.25rem;
      cursor: pointer;
      width: 100%;
      max-width: 30%;
      margin: 0 auto;
    }
    @media (max-width: 768px) {
      .fzbe__container .fzbe__form .fzbe__btn-container .fzbe__btn {
        max-width: 100%;
      }
    }
    .fzbe__container .fzbe__trigger {
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      border-radius: 999px;
      width: 70px;
      height: 70px;
      background-color: white;
      position: absolute;
      bottom: 3rem;
      right: 1rem;
      font-size: 1rem;
    }
    .fzbe__container .fzbe__trigger svg {
      width: 35px;
      height: 35px;
    }
    .fzbe__container .fzbe__trigger svg.hide {
      display: none;
    }
    .fzbe__container .fzbe__trigger svg.show {
      display: block;
    }
    @media screen and (min-width: 768px) {
      .fzbe__container .fzbe__trigger {
        display: none;
      }
    }
    @media screen and (max-width: 768px) {
      .fzbe__container .fzbe__form {
        display: none;
      }
    }
    .fzbe__container.ui-float {
      background-color: rgba(91, 91, 91, 0.2784313725);
      top: 0;
      left: 0;
      right: 0;
      height: 100vh;
      width: auto;
      display: block;
    }
    .fzbe__container.ui-float .fzbe__form {
      display: flex;
      flex-direction: column;
      background-color: white;
      border-radius: 1rem;
      padding: 1rem;
      margin: auto;
      width: 80%;
      position: absolute;
      margin: auto;
      left: 0;
      right: 0;
      bottom: 9rem;
    }
    .fzbe__container.ui-float .fzbe__form .fzbe__form-control .fzbe__input {
      width: -webkit-fill-available;
    }
    .fzbe__container.ui-float .fzbe__trigger {
      bottom: 3rem;
      right: 1rem;
    }
    .fzbe__container.ui-float .fzbe__trigger svg.show {
      display: none;
    }
    .fzbe__container.ui-float .fzbe__trigger svg.hide {
      display: block;
    }
    @media screen and (min-width: 768px) {
      .fzbe__container {
        z-index: 100;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.06);
        border-radius: 8px;
        padding: 15px;
      }
    }

    #location-dropdown {
      font-size: 1rem;
      padding: 0.575rem 0.75rem;
      border: 1px solid #ccc;
      border-radius: 4px;
      background-color: #fff;
      width: 100%;
      box-sizing: border-box;
      line-height: 1.5;
      text-transform: capitalize;
    }

    #location-dropdown option {
      font-size: 16px;
      padding: 5px;
      text-transform: capitalize;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }/*# sourceMappingURL=style.css.map */
`;

  // Append the <style> element to the <head>
  document.head.appendChild(style);
  const widgetContainer = document.createElement("div");
  widgetContainer.innerHTML = `
        <div class="fzbe__container" id="fzbe__container">
            <form action="#" class="fzbe__form">
                <div class="fzbe__input-container">
                    <div class="fzbe__form-control">
                        <label class="fzbe__label" for="CheckInDate">Check in</label>
                        <input type="date" name="CheckInDate" id="checkindate" class="fzbe__input fzbe__input-date" value="" min=""/>
                    </div>
                    <div class="fzbe__form-control">
                        <label class="fzbe__label" for="CheckOutDate">Check Out</label>
                        <input type="date" name="CheckOutDate" id="checkoutdate" class="fzbe__input fzbe__input-date" min="" value=""/>
                    </div>
                    <div class="fzbe__form-control" id="location-container" style="display: none">
                        <label class="fzbe__label" for="Rooms">Location</label>
                        <select id="location-dropdown"></select>
                    </div>
                    <div class="fzbe__form-control">
                        <label class="fzbe__label" for="Rooms">Rooms</label>
                        <input type="number" min="1" name="Rooms" id="room" class="fzbe__input fzbe__input-number" value="1"/>
                    </div>
                    <div class="fzbe__form-control">
                        <label class="fzbe__label" for="Adult">Adult</label>
                        <input type="number" min="1" name="Adult" id="adult" class="fzbe__input fzbe__input-number" value="1"/>
                    </div>
                    <div class="fzbe__form-control">
                        <label class="fzbe__label" for="Child">Child</label>
                        <input type="number" min="0" name="Child" id="child" class="fzbe__input fzbe__input-number" value="0"/>
                    </div>
                </div>
                <div class="fzbe__btn-container">
                    <button type="submit" class="fzbe__btn" id="BookNow">Book Now</button>
                </div>
            </form>
        
            <button class="fzbe__trigger" id="fzbe__trigger">
                <svg xmlns="http://www.w3.org/2000/svg" class="show" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <path d="M4 7a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12z"/>
                    <path d="M16 3v4"/>
                    <path d="M8 3v4"/>
                    <path d="M4 11h16"/>
                    <path d="M7 14h.013"/>
                    <path d="M10.01 14h.005"/>
                    <path d="M13.01 14h.005"/>
                    <path d="M16.015 14h.005"/>
                    <path d="M13.015 17h.005"/>
                    <path d="M7.01 17h.005"/>
                    <path d="M10.01 17h.005"/>
                </svg>
        
                <svg xmlns="http://www.w3.org/2000/svg" class="hide" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <path d="M18 6l-12 12"/>
                    <path d="M6 6l12 12"/>
                </svg>
            </button>
        </div>
    `;
  document.body.appendChild(widgetContainer);

  // Const variables
  const today = new Date();
  const next = new Date(today);
  next.setDate(today.getDate() + 1);
  const todayDate = today.toISOString().split("T")[0];
  const nextDate = next.toISOString().split("T")[0];
  let propertyCode = "",
    propertyName = "";

  // Element Selectors
  const checkInDateInput = document.getElementById("checkindate");
  const checkOutDateInput = document.getElementById("checkoutdate");
  const roomTextInput = document.getElementById("room");
  const adultTextInput = document.getElementById("adult");
  const childTextInput = document.getElementById("child");
  const locationContainer = document.getElementById("location-container");
  const locationDropDown = document.getElementById("location-dropdown");
  const bookNowBtn = document.getElementById("BookNow");
  const modalOpener = document.getElementById("fzbe__trigger");

  // Setters
  checkInDateInput.value = todayDate;
  checkOutDateInput.value = nextDate;
  checkInDateInput.setAttribute("min", todayDate);
  checkOutDateInput.setAttribute("min", nextDate);

  // Event Listeners
  checkInDateInput.addEventListener("change", () => {
    const checkInDate = new Date(checkInDateInput.value);
    const minCheckOutDate = new Date(checkInDate);
    minCheckOutDate.setDate(checkInDate.getDate() + 1);
    checkOutDateInput.setAttribute(
      "min",
      minCheckOutDate.toISOString().split("T")[0]
    );
    if (new Date(checkOutDateInput.value) <= minCheckOutDate) {
      checkOutDateInput.value = minCheckOutDate.toISOString().split("T")[0];
    }
  });

  modalOpener.addEventListener("click", function (e) {
    e.preventDefault();
    const classlist = document.getElementById("fzbe__container").classList;
    classlist.contains("ui-float")
      ? classlist.remove("ui-float")
      : classlist.add("ui-float");
  });

  bookNowBtn.addEventListener("click", (e) => {
    e.preventDefault();
    propertyName = locationDropDown.value.split("_")[0] || propertyName;
    propertyCode = locationDropDown.value.split("_")[1] || propertyCode;
    console.log(propertyCode);
    const bookingData = {
      checkInDate: checkInDateInput.value,
      checkOutDate: checkOutDateInput.value,
      room: roomTextInput.value || 1,
      adult: adultTextInput.value || 1,
      child: childTextInput.value || 0,
      propertyCode,
      propertyName,
    };

    const redirectUrl = urlGenerator({ ...bookingData });
    window.open(redirectUrl, "_blank");
  });

  async function init() {
    try {
      const response = await fetchApi();

      if (response.statusCode === 200) {
        const data = response.data;

        if (locationDropDown) {
          locationDropDown.innerHTML = "";

          if (
            data.Chain &&
            data.Chain.Properties &&
            data.Chain.Properties.length > 0
          ) {
            data.Chain.Properties.sort((a, b) =>
              a.Name.localeCompare(b.Name)
            ).forEach((property) => {
              const option = document.createElement("option");
              option.value = `${property.Name}_${property.Code}`;
              option.textContent = `${property.Name} (${property?.Address?.City}) `;
              locationDropDown.appendChild(option);
            });

            locationContainer.style.display = "block";
          } else {
            propertyCode = data.Code;
            propertyName = data.Name;
            locationContainer.style.display = "none";
          }
        }
      } else {
        console.error("Failed to fetch data:", response.statusCode);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  init();
});
